import { Container, Typography } from '@mui/material';

import exchangeWordsWithLinks from './utils';
import theme from '../../../styles/theme';

const TermsAndConditions = ({ terms }) => {
  if (!terms?.termsText) {
    return null;
  }

  const renderedText = exchangeWordsWithLinks(terms.termsText, terms.links);
  return (
    <Container sx={{
      textAlign: 'center', paddingY: 8, bgcolor: theme.palette.background.default,
    }}
    >
      <Typography color="text.primary" variant="body2">{renderedText}</Typography>
    </Container>
  );
};

export default TermsAndConditions;
