/* eslint-disable max-len */
import getConfig from 'next/config';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

export const {
  apiKey,
  apiUrl,
  apiUrl_SSR,
  brazeApiKey,
  brazeLogEnabled,
  brazeSDKEndpoint,
  baseUrl,
  isDev,
  didomiApiKey,
  didomiLogoUrl,
  didomiSDKPath,
  tealiumUrl,
  cookieDomain,
  gemiusEnabled,
  googleAnalyticsEnabled,
  xitiEnabled,
  messageProxyUrl,
  searchParamsUrl,
  searchParamsUrl_SSR,
  shopApiBaseUrl,
  shopApiBaseUrl_SSR,
  jfgShopApiBaseUrl,
  jfgShopApiBaseUrl_SSR,
  tilesBaseUrl,
  blogUrl_SSR,
  countdownFor,
  countdownStartDate,
  countdownEndDate,
  countdownDiscountPercentage,
  countdownDiscountText,
  countdownDisplaySubdomains,
  jobPromotionStartDate,
  jobPromotionEndDate,
  jobPromotionDiscountText,
  jobPromotionWhenText,
  appleLoginVisible,
  facebookLoginVisible,
  googleLoginVisible,
  fbLoginAppId,
  googleLoginAppId,
  fbAppId,
  suggestedAdsBaseUrl,
  suggestedAdsBaseUrl_SSR,
  geoApiUrl,
  staticBlocketUrl,
  verboliaApiBaseUrl_SSR,
  classifiedUrl,
  dsaReportUrl,
  verboliaApiKey,
  profileApiBaseUrl,
  profileApiBaseUrl_SSR,
  cacheLifetime,
  messagingVersion,
  messagingNotifySellerModal,
  streamApiKey,
  announcementBaseUrlSSR,
  sentry,
} = publicRuntimeConfig;

const transformBaseUrl = (url) => (
  url.replace('https://www.', '')
);

export const environment = baseUrl.includes('.jofogas.hu') ? 'pro' : 'pre';
export const base2Url = publicRuntimeConfig.baseUrl.replace('www', 'www2');
export const accountUrl = `${publicRuntimeConfig.apiUrl}/v2/account`;
export const accountUrl_SSR = `${publicRuntimeConfig.apiUrl_SSR}/v2/account`;
export const homeCategoriesUrl = `${serverRuntimeConfig.homeCategoriesBaseUrl}/categories`;
export const loginRedirectUrl = `${publicRuntimeConfig.baseUrl}/postlogin`;
export const searchSuggesterUrl = `${publicRuntimeConfig.searchSuggesterBaseUrl}/suggestions`;
export const draftAdUrl = `${publicRuntimeConfig.draftAdBaseUrl}/drafts`;
export const adsBaseUrl = `${publicRuntimeConfig.apiUrl}/v2/items`;
export const adsBaseUrl_SSR = `${publicRuntimeConfig.apiUrl_SSR}/v2/items`;
export const classifiedAdUrl = `${publicRuntimeConfig.classifiedUrl}/classified`;
export const friendlyURL = `${publicRuntimeConfig.apiUrl}/v2/config/friendlyURL`;
export const friendlyURL_SSR = `${publicRuntimeConfig.apiUrl_SSR}/v2/config/friendlyURL`;
export const suggestedAdsUrl = `${publicRuntimeConfig.suggestedAdsBaseUrl}/v4/getAdBasedSuggestions`;
export const suggestedAdsUrl_SSR = `${publicRuntimeConfig.suggestedAdsBaseUrl_SSR}/v4/getAdBasedSuggestions`;
export const verboliaApiOrigin = 'https://www.jofogas.hu';
export const verboliaApiUrl_SSR = `${publicRuntimeConfig.verboliaApiBaseUrl_SSR}/linkingengine/getlinkings/Jofogas/hu-HU`;
export const systemMessageUrl = `${publicRuntimeConfig.messageApiUrl}/systemMessage`;
export const imageServer = environment === 'pro' ? 'img.jofogas.hu' : 'img.test-jofogas.hu';
export const orderBaseUrl = `${publicRuntimeConfig.apiUrl}/v2/order`;
export const phoneBaseUrl = `${publicRuntimeConfig.apiUrl}/v2/phone`;
export const addressBaseUrl = `${publicRuntimeConfig.apiUrl}/v2/address`;
export const discussionsUrl = `${publicRuntimeConfig.apiUrl}/v2/discussions`;
export const deliveryInfoUrl_SSR = `${publicRuntimeConfig.apiUrl_SSR}/v2/delivery/info`;
export const foxpostPackagePointUrl = `${publicRuntimeConfig.apiTemplateUrl}/foxpostcsomagpont.json`;
export const glsPackagePointUrl = `${publicRuntimeConfig.apiTemplateUrl}/glscsomagpont.json`;
export const dac7UsersUrl = `${publicRuntimeConfig.dac7ApiUrl}/users`;
export const dsaAdReportUrl = `${publicRuntimeConfig.dsaReportUrl}/dsa`;
export const infoBoxUrl_SSR = `${publicRuntimeConfig.announcementBaseUrl_SSR}/info`;
export const heroDataUrl_SSR = `${publicRuntimeConfig.announcementBaseUrl_SSR}/hero`;
export const heroImageUrl = `${publicRuntimeConfig.announcementBaseUrl}/img/`;
export const defaultProfilePic = '/assets/img/default_avatar.png';
export const defaultShopPic = '/assets/shops/shop_placeholder.svg';
export const defaultAdPic = '/assets/img/ad-placeholder-2023.png';

export const jobPartnerBg = '/assets/landing/allas_partner.png';
export const jobPromoBg = '/assets/landing/allas_promo_bg_50.png';

export const homeUrl = isDev ? '/' : publicRuntimeConfig.baseUrl;
export const jobHomeUrl = isDev ? '/allas' : `https://allas.${transformBaseUrl(publicRuntimeConfig.baseUrl)}`;
export const carHomeUrl = isDev ? '/auto' : `https://auto.${transformBaseUrl(publicRuntimeConfig.baseUrl)}`;
export const realEstateHomeUrl = isDev ? '/ingatlan' : `https://ingatlan.${transformBaseUrl(publicRuntimeConfig.baseUrl)}`;

export const polygonsForPointUrl = `${geoApiUrl}/query/poligons_for_point_v2`;
export const publicTransportUrl = `${geoApiUrl}/travel/stations_from_lat_lon`;
export const polygonsUrl = `${staticBlocketUrl}/polygons`;

export const messagingV2SessionTokenUrl = `${publicRuntimeConfig.messageApiUrl}/v2/sessionToken`;
export const messagingV2ContactSellerUrl = `${publicRuntimeConfig.messageApiUrl}/v2/contactSeller`; // INFO: New contact seller for messagingV2
export const messagingV2ContactSellerAttachmentUrl = `${publicRuntimeConfig.messageApiUrl}/v2/contactSeller/attachment`; // INFO: New contact seller attachment for messagingV2
export const messagingV2UnreadMessageCountUrl = `${publicRuntimeConfig.messageApiUrl}/v2/unreadMessageCount`; // INFO: New contact seller for messagingV2
export const messagingV2DeleteChannelUrl = `${publicRuntimeConfig.messageApiUrl}/v2/channel`; // INFO: {channel_id}
export const messagingV2AdInfoUrl = `${publicRuntimeConfig.messageApiUrl}/v2/adInfo`; // INFO: {list_id}
export const messagingV2CreateUser = `${publicRuntimeConfig.messageApiUrl}/v2/createUser`;

export const blogUrl = 'https://blog.jofogas.hu';

export const chatWebSocketUrl = `${publicRuntimeConfig.webSocketUrl}/chat`;

export const phoneValidationPhoneAddress = '+36303444292';

export const fileUpload = {
  maxFileCount: 4,
  maxFileSizeInMb: 16,
  maxFileSizeInBytes: 16 * 1024 * 1024,
  acceptedFiles: ['.pdf', '.doc', '.docx', 'image/*'],
  acceptedMimeTypes: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/',
  ],
};

export const phoneRegex = {
  default: /((( *, *)?(((\+3|0)6[-/ ]?(([2-9][1-9][-/ ]?\d{3}[- ]?\d{3})|([2-9][1-9][-/ ]?\d{2}[- ]?\d{2}[- ]?\d{2})|(2?1[-/ ]?\d{3}[- ]?\d{2}[- ]?\d{2})|(2?1[-/ ]?\d{2}[- ]?\d{2}[- ]?\d{3})|((20|30|31|50|70)[-/ ]?\d{3}[- ]?\d{2}[- ]?\d{2})|((20|30|31|50|70)[-/ ]?\d{2}[- ]?\d{2}[- ]?\d{3})))|(\+421[-/ ]?((\d{1}[-/ ]?\d{4}[-/ ]?\d{4})|(\d{2}[-/ ]?\d{4}[-/ ]?\d{3})))|(\+380[- ]?\d{2}[- ]?\d{3}[- ]?\d{2}[- ]?\d{2})|(\+40[- ]?\d{3}[- ]?\d{3}[- ]?\d{3})|(\+381[- ]?\d{2}[- ]?\d{3}[- ]?\d{4})|(\+30[- ]?\d{10}))( *\/ *\d{1,5})?){1,})/gmi,
  // eslint-disable-next-line no-useless-escape
  phoneFormat: /[^\d\+\(\)]/gmi,
  hungarianFormat: /((?:\+?3|0)6)(?:-|\()?([\s]?)(\d{1,2})(?:-|\))?([\s]?)(\d{3})-?(\d{3,4})/gmi,
};

export const docUrls = {
  jfg: {
    privacyPolicy: 'https://adatvedelem.jofogas.hu/adatvedelmi-tajekoztato/',
    termsOfCondition: 'https://jofogas.hu/szabalyzat',
  },
  gls: {
    privacyPolicy: 'https://gls-group.eu/HU/hu/adatvedelmi-szabalyzat',
    termsOfCondition: 'https://gls-group.eu/HU/hu/altalanos-uzleti-feltetelek',
  },
  foxpost: {
    privacyPolicy: 'https://foxpost.hu/uploads/documents/hu/adatkezelesi_szabalyzat.pdf',
    termsOfCondition: 'https://foxpost.hu/uploads/documents/hu/foxpost_aszf.pdf',
  },
  hdt: {
    privacyPolicy: 'https://www.homedt.hu/documents/adatvedelmi_tajekoztato.pdf',
    termsOfCondition: 'https://www.homedt.hu/documents/aszf.pdf',
  },
};

export const euStates = [
  {
    code: 'AT',
    name: 'Ausztria',
  },
  {
    code: 'BE',
    name: 'Belgium',
  },
  {
    code: 'BG',
    name: 'Bulgária',
  },
  {
    code: 'CY',
    name: 'Ciprus',
  },
  {
    code: 'CZ',
    name: 'Csehország',
  },
  {
    code: 'DK',
    name: 'Dánia',
  },
  {
    code: 'EE',
    name: 'Észtország',
  },
  {
    code: 'FI',
    name: 'Finnország',
  },
  {
    code: 'FR',
    name: 'Franciaország',
  },
  {
    code: 'EL',
    name: 'Görögország',
  },
  {
    code: 'NL',
    name: 'Hollandia',
  },
  {
    code: 'HR',
    name: 'Horvátország',
  },
  {
    code: 'IE',
    name: 'Írország',
  },
  {
    code: 'PL',
    name: 'Lengyelország',
  },
  {
    code: 'LV',
    name: 'Lettország',
  },
  {
    code: 'LT',
    name: 'Litvánia',
  },
  {
    code: 'LU',
    name: 'Luxemburg',
  },
  {
    code: 'HU',
    name: 'Magyarország',
  },
  {
    code: 'MT',
    name: 'Málta',
  },
  {
    code: 'DE',
    name: 'Németország',
  },
  {
    code: 'IT',
    name: 'Olaszország',
  },
  {
    code: 'PT',
    name: 'Portugália',
  },
  {
    code: 'RO',
    name: 'Románia',
  },
  {
    code: 'ES',
    name: 'Spanyolország',
  },
  {
    code: 'SE',
    name: 'Svédország',
  },
  {
    code: 'SK',
    name: 'Szlovákia',
  },
  {
    code: 'SI',
    name: 'Szlovénia',
  },
  {
    code: 'NON_EU',
    name: 'Nem EU ország',
  },
];

export const dateFormatForServer = 'YYYY-MM-DD';

export const maxNumOfLastSeenAds = 24;
