import { destroyCookie } from 'nookies';

import measurableFetch from './measurableFetch';
import { cookieDomain } from '@/config';

const logout = () => {
  const cookieOptions = { domain: cookieDomain, path: '/' };
  destroyCookie(null, 'session_id', cookieOptions);
  destroyCookie(null, 'session_http', cookieOptions);
  localStorage.removeItem('token');
};

const authenticatedFetch = (url, options) => measurableFetch(url, options)
  .then((response) => {
    if (response?.status === 401) {
      logout();
      window.location.assign(window.location);
    }
    return response;
  });

export default authenticatedFetch;
