import { useCallback } from 'react';

import NextHead from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { NextSeo } from 'next-seo';
import PropTypes from 'prop-types';

import StructuredData from '../../marketing/StructuredData';
import { baseUrl } from '@/config';
import { useMarketingContext } from '@/contexts/Marketing/MarketingContext';

const Head = ({
  title, description, canonical, productJsonLdData, ...args
}) => {
  const router = useRouter();
  const { setAdverticumReady } = useMarketingContext();
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={canonical}
        openGraph={{
          url: `${baseUrl}${router.pathname}`,
        }}
        {...args}
      />
      <NextHead>
        <link rel="icon" href="/assets/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
      </NextHead>
      <Script
        strategy="lazyOnload"
        src="https://ad.adverticum.net/g3.js"
        onLoad={useCallback(() => {
          window.goAdverticum3.onReady(() => {
            setAdverticumReady(true);
          });
        }, [setAdverticumReady])}
      />
      <StructuredData productJsonLdData={productJsonLdData} />
    </>
  );
};
Head.defaultProps = {
  canonical: baseUrl,
};

Head.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  canonical: PropTypes.string,
};

export default Head;
