import logger from '../server/log/logger';
import { isServerRun, isTestRun } from '@/helpers/utils';

// Switch to disable fetching metrics at build time
const NEXT_BUILD = parseInt(process.env.NEXT_BUILD, 2) || 0;
const APP_NAME = 'dominion';
/**
 * Wrapper around the `fetch` API to automatically measure the fetching time
 * @param {RequestInfo} url
 * @param {RequestInit} [options]
*/
const measurableFetch = (url, options) => {
  try {
    const startTime = new Date();
    let fetchUrlRequest;
    let statusCode;
    if (typeof url === 'string') {
      fetchUrlRequest = new URL(url);
    } else if (url instanceof URL) {
      fetchUrlRequest = url;
    } else if (url.url) { // This is an instance of Request, but we can't test it in Jest
      fetchUrlRequest = new URL(url.url);
    } else {
      throw new Error(`measurableFetch: parameter url is not a valid url string or object, ${url}`);
    }

    return fetch(url, options)
      .then((response) => {
        statusCode = response.status;
        return response;
      })
      .catch((e) => {
        if (isServerRun()) {
          logger.child({
            name: 'Measurable fetch',
            context: {
              url,
              options,
            },
          }).error(e);
        }
      })
      .finally(() => {
        if (isServerRun() && !isTestRun() && NEXT_BUILD === 0) {
          global.serverFetchDurationMilliseconds
            .labels(options?.method || 'GET', fetchUrlRequest.hostname, fetchUrlRequest.pathname, APP_NAME, statusCode)
            .observe(Date.now() - startTime);
        }
      });
  } catch (e) {
    throw new Error(`measurableFetch: error fetching url ${url}`);
  }
};

export default measurableFetch;
