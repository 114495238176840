import { defaultAdPic } from '@/config';

// Ad helpers
const getParameter = (item, name) => {
  const parameter = item.parameters.find((param) => param.key === name);
  if (parameter) {
    return parameter.values[0].label || parameter.values[0].value;
  }
  return '';
};

const getBadges = (item) => (item.badges ? item.badges.filter((badge) => badge.type !== 'urgent' && badge.label) : null);

const getRibbon = (item) => (item.badges ? item.badges.find((badge) => badge.type === 'urgent') : null);

const getShippingMethod = (type) => {
  const map = {
    locker_to_locker: 'locker2locker',
    address_to_locker: 'door2locker',
    address_to_address: 'door2door',
  };
  if (map[type] === undefined) {
    throw new Error('Unknown delivery type');
  }
  return map[type];
};

const getListImage = (images) => (images
  ? images[0].image_size_variations.find((image) => image.type === 'images').url
  : defaultAdPic);

const isSSR = () => typeof window === 'undefined';

const isValidDate = (date) => (new Date(date).toString() !== 'Invalid Date' && !Number.isNaN(new Date(date)));

const getParameterValue = (values = []) => values.reduce(
  (acc, param) => {
    let returns = '';
    if (acc !== '') {
      returns = `${acc}, `;
    }
    return `${returns}${param?.label || param?.value}`;
  },
  '',
);

const transformParameters = ({ parameters = [], blacklist = [] }) => parameters
  .filter((parameter) => blacklist.indexOf(parameter.key) === -1)
  .sort((a, b) => a - b)
  .map(({
    key,
    values,
    label,
    order,
  }) => ({
    key,
    value: getParameterValue(values),
    label,
    order,
    testId: `param_${key}`,
  }));

const formatCategoryPath = (categoryPath) => {
  if (Array.isArray(categoryPath)) {
    return JSON.stringify(categoryPath);
  }
  return categoryPath;
};

const containsOnlyDigits = (string) => ((/^\d+$/)).test(string);

const stringifyCategoryPath = (categoryPath) => {
  if (Array.isArray(categoryPath)) {
    return categoryPath.map((element) => element.name).join(' > ');
  }
  return categoryPath;
};

const isServerRun = () => typeof window === 'undefined';

const isTestRun = () => process.env.JEST_WORKER_ID !== undefined;

const getOriginFieldsforTealium = () => {
  const originFields = {};
  const url = window.location.href;
  const params = Object.fromEntries(new URLSearchParams(window.location.search));

  Object.entries(params).forEach((entry) => {
    const [key, value] = entry;
    originFields[key.replace('pls_', '')] = value;
  });

  return { url, ...originFields };
};

export {
  getParameter,
  getBadges,
  getListImage,
  getRibbon,
  getShippingMethod,
  isSSR,
  isValidDate,
  transformParameters,
  formatCategoryPath,
  containsOnlyDigits,
  stringifyCategoryPath,
  isServerRun,
  isTestRun,
  getOriginFieldsforTealium,
};
